.contact-icons a {
  font-size: 3rem;
  margin: 1em;
  z-index:5;
  position: relative;
}

.contact-icons a:hover {
  color: #0fd38b;
}



.flip {
  height:50px;
  overflow:hidden;
}

.flip > div > h3 {
  color:#00d285;
  padding:4px 12px;
  height:45px;
  margin-bottom:45px;
  display:inline-block;
}

.flip div:first-child {
  animation: show 7s linear infinite;
}

@keyframes show {
  0% {margin-top:-270px;}
  5% {margin-top:-180px;}
  33% {margin-top:-180px;}
  38% {margin-top:-90px;}
  66% {margin-top:-90px;}
  71% {margin-top:0px;}
  99.99% {margin-top:0px;}
  100% {margin-top:-270px;}
}



