
@keyframes cursor {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: #00d285;
  }
}
@keyframes typing {
  from {
    width: 100%;
  }
  90%, to {
    width: 0;
  }
}
@keyframes slide {
  33.3333333333% {
    font-size: 3rem;
    letter-spacing: 3px;
  }
  to {
    font-size: 0;
    letter-spacing: 0;
  }
}
.typing-slider {
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
}

.typing-slider p {
  color: #00d285;
  position: relative;
  display: inline;
  font-size: 0;
  text-transform: uppercase;

  animation: slide 15s step-start infinite;
}

.typing-slider p::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-left: 3px solid #00d285;
  background-color: #fff;
  animation: typing 5s infinite, cursor 1s infinite;
}

.typing-slider p:nth-child(1) {
  animation-delay: 0s;
}
.typing-slider p:nth-child(1)::after {
  animation-delay: 0s;
  animation-timing-function: steps(16), step-end;
}

.typing-slider p:nth-child(2) {
  animation-delay: 5s;
}
.typing-slider p:nth-child(2)::after {
  animation-delay: 5s;
  animation-timing-function: steps(23), step-end;
}

.typing-slider p:nth-child(3) {
  animation-delay: 10s;
}
.typing-slider p:nth-child(3)::after {
  animation-delay: 10s;
  animation-timing-function: steps(12), step-end;
}