.grid-container {
  z-index: 2;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  width: auto;
  padding-bottom: 1rem;
  display: grid;
  position: relative;
}


.grid-item-info {
  grid-column-gap: 1rem;
  border: 1px solid rgba(255, 255, 255, 0);
  border-radius: .75rem;
  flex-direction: column;
  width: auto;
  height: 100%;
  padding: 1rem;
  display: flex;
}

.grid-item-image-wrapper {
  overflow: hidden;
}

.grid-item-image-wrapper img {
  aspect-ratio: 16 / 9;
  object-fit: cover;
  border-radius: .5rem .5rem 0 0;
  width: 100%;
  height: auto;
  object-position: top;
}

.grid-item-content-wrapper {
  padding: 1rem;
  border-radius: 0 0 .5rem .5rem;
}

.grid-item-content-wrapper i {
  font-style: normal;
  margin: 1rem 0px;
  display: block;
}

.grid-item-content-wrapper i::before {
  margin-right: 5px;
}

.grid-item {
  border-radius: .5rem;
  border-bottom: 0px solid #E6ECF8;
  box-shadow: 0 5px 5px 0 rgba(233, 240, 243, 0.5), 0 0 0 1px #E6ECF8;
}

.design-links a:hover {
  color: #00d285;
}

@media screen and (max-width: 991px) {
  .grid-container {
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }

}

@media screen and (max-width: 600px) {
  .grid-container {
    grid-template-columns: 1fr;
    width: 100%;
  }
  .recent-work .container-xl {
    padding: 0 20px;
  }

  .desktop-wrapper {
    display: none;
  }
}

