header {
  display: flex;
  background-color: rgb(255,255,255, .8);
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  border-bottom: 1px solid #00d285;
  padding: 20px 50px;
}

.menu-item {
  list-style: none;
  padding-left: 2em;
  font-weight: 500;
}

.menu-item a {
  color: #333;
  transition: .3s ease;
}

.open .menu-item a {
  color: #000;
}

header .menu {
  display: none;
} 

header nav .menu.open  {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #E5FAF2;
  width: 100%;
  height: 100vh;
  z-index: 12;
} 

header nav .menu li {
  cursor: pointer;
  
} 

header nav .menu.open li {
  cursor: pointer;
  font-size: 24px;
  margin-bottom: 32px;
} 

header nav .menu-icon {
  cursor: pointer;
} 

header nav .menu.open .menu-icon {
  display: none;
} 

header nav .close-icon {
  display: none;
} 

header nav .menu.open .close-icon {
  display: initial;
  color :#000;
} 

header div.menu-icon img {
  width: 24px;
} 


@media only screen and (min-width: 768px) {
  .container {
      max-width: 720px;
  }

  header .menu {
      display: flex;
  } 

  header ul li {
      font-size: 18px;
  } 

  header div.menu-icon {
      display: none;
  } 

}

@media only screen and (max-width: 768px) {
  header nav.open .menu li a {
    color: #fff !important;
  }
  header {
    padding: 10px 20px;
  }
  .menu-item {
    list-style: none;
    padding-left: 0px;
  }
  header nav .menu.open {
    padding-left: 0px;
  }
}

.menu-item a:hover {
  color: #0fd38b;
}


/* HEADER SCROLL ANIMATION */
.page-header.is-sticky {
  position: fixed;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);
  padding: 10px 50px;
  backdrop-filter: blur(10px);
  animation: slideDown 0.35s ease-out;
}


@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}


/* HEADER TEXT ANIMATION */
.logo-text .box {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.logo-text .box .title {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
}

.logo-text .box .title .block {
  width: 0%;
  height: inherit;
  background: #00d285;
  position: absolute;
  animation: mainBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  display: flex;
}

.logo-text .box .title h2 {
  color: #000;
  -webkit-animation: mainFadeIn 2s forwards;
  -o-animation: mainFadeIn 2s forwards;
  animation: mainFadeIn 2s forwards;
  animation-delay: 1.6s;
  opacity: 0;
  display: flex;
  align-items: baseline;
  position: relative;
}

.logo-text .box .title h2 span {
  width: 0px;
  height: 0px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #00d285;
  -webkit-animation: load 0.6s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation: popIn 0.8s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation-delay: 2s;
  margin-left: 5px;
  margin-top: -10px;
  position: absolute;
  bottom: 13px;
  right: -8px;
}


@keyframes mainBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes secBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes mainFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes popIn {
  0% {
    width: 0px;
    height: 0px;
    background: #0fd38b;
    border: 0px solid #ddd;
    opacity: 0;
  }
  50% {
    width: 10px;
    height: 10px;
    background: #0fd38b;
    opacity: 1;
    bottom: 45px;
  }
  65% {
    width: 7px;
    height: 7px;
    bottom: 0px;
    width: 15px;
  }
  80% {
    width: 10px;
    height: 10px;
    bottom: 20px;
  }
  100% {
    width: 6px;
    height: 6px;
    background: #0fd38b;
    
    bottom: 6.7px;
  }
}


