.App {
  text-align: center;
}

h1 {
  font-size: 4rem !important;
}

h3 {
  font-weight: 400 !important;
}

h4 {
  font-weight: 400 !important;
}

.container-xl {
  max-width: 1200px;
}

a {
  text-decoration: none;
  color: #000;
}

.w-20 {
  width: 20% !important;
}

.btn-primary {
  padding: 15px 20px;
  transition: 1s ease;
}

.btn-primary:hover {
  background: #0bb073;
  border-color: #0bb073;
  color: #fff;
}

body {
  height: 100vh;
}

.scrollbar-thumb {
  background: #0fd38b !important;
}


@media only screen and (max-width: 768px) {
  .btn-primary {
    width: auto !Important;
  }

}


@media only screen and (max-width: 990px) {
  h1 {
    font-size: 3rem !Important;
  }

}