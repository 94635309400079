

$primary: #0fd38b;
$secondary: #E5FAF2;
$btn-color: #ffffff !important;
$font-family-base: Ubuntu;
$body-color: #333;


@import 'bootstrap/scss/bootstrap.scss';
