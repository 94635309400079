
/* SLIDESHOW */
.carousel-container {
  margin: 20px;
}

.selected-image {
  background-repeat: no-repeat;
}

.carousel {
  position: relative;
}

.carousel-images {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  overflow-x: hidden;
  justify-content: center;

}

.carousel-image {
  border: 3px solid #fff;
}

.carousel-image:hover {
  border: 3px solid #0fd38b !important;
}

.carousel-image-selected {
  border: 3px solid #0fd38b !important;
}

.carousel-image {
  margin: 20px 25px;
  height: 150px;
  min-width: 150px;
  box-shadow: 1px 2px 10px rgb(0 0 0 / 23%);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Desktop PROJECTS PAGE */
.mockup-scroll {
  height: calc(-202px + 100vh);
}

.mockup-scroll-wrap {
  overflow: scroll;
  height: 100%;
  overflow-x: hidden !important;
}

.view-img {
  height: calc(100vh - 202px);
  position: relative;
}

.laptop-wrapper {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.laptop-wrapper img {
  max-width: 100%;
  border: 0;
  height: auto;
}

.mockup-data {
  width: 75%;
  height: 84%;
  position: absolute;
  top: 6.6%;
  left: 12.5%;
}

.laptop-wrapper .mockup-scroll {
  height: 100%;
  overflow: hidden;
  position: relative;
}


/* PHONE RENDERING */
.phone-wrapper {
  position: relative;
  max-width: 370px;
  margin: 0 auto;
}
.phone-wrapper img {
  max-width: 100%;
  border: 0;
  height: auto;
}

.mobile-mockup-data {
    width: 89.6%;
    height: 96%;
    position: absolute;
    top: 1.9%;
    left: 5%;
    border-radius: 30px;
    overflow: hidden;
    transform: translateZ(1px);
}

.mobile-mockup-scroll {
  overflow: hidden;
  position: relative;
  height: 100%;
}



@media only screen and (max-width: 600px) {
  .carousel-image {
    margin: 10px;
    height: 120px;
    min-width: 120px;
    display: flex;
    flex-basis: calc(50% - 40px);
    flex-direction: column;
    justify-content: center;
  }


}