.scouting-report img {
  border: 1px solid #0fd38b;
  border-radius: 50%;
  padding: 25px;
  overflow: visible;
}

.scouting-image {
  height: 170px;
  display: flex;
  align-items: center;
}

.scouting-description ul {
  list-style-position: inside;
}

@media only screen and (max-width: 575px) {
  .scouting-description  {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .scouting-description .skills-list {
    margin-right: 20px;
  }
}

.scouting-description ul {
  padding-left: 1rem;
}