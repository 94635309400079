.hero-content {
  z-index: 2;
  position: relative;
}

.hero-images img {
  width: 20%;
}

/* ICON ANIMATIONS  */
.home-hero .photoshop {
  position: absolute;
  display: block;
  animation: animate 25s linear infinite;
  bottom: -150px;
  left: 40%;
  width: 60px;
  height: 55px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.home-hero .illustrator {
  position: absolute;
  display: block;
  animation: animate 25s linear infinite;
  bottom: -150px;
  left: 65%;
  width: 50px;
  height: 45px;
  animation-delay: 0s;
}

.home-hero .react {
  position: absolute;
  display: block;
  animation: animate 25s linear infinite;
  bottom: -150px;
  left: 25%;
  width: 60px;
  height: 55px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.home-hero .indesign {
  position: absolute;
  display: block;
  animation: animate 25s linear infinite;
  bottom: -150px;
  left: 17%;
  width: 50px;
  height: 45px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.home-hero .squares {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.home-hero .squares li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #00d285;
  animation: animate 25s linear infinite;
  bottom: -150px;
}


.home-hero .squares li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.home-hero .squares li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}


.home-hero .squares li:nth-child(5){
  left: 50%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
}

.home-hero .squares li:nth-child(7){
  left: 35%;
  width: 120px;
  height: 120px;
  animation-delay: 7s;
}

.home-hero .squares li:nth-child(8){
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.home-hero .squares li:nth-child(9){
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.home-hero .squares li:nth-child(10){
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {

  0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }

  100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }

}

/* TEXT ANIMATIONS */
@keyframes showTopText {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  40%,
  60% {
    transform: translate3d(0, 50%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes showBottomText {
  0% {
    transform: translate3d(0, -100%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}


.home-hero .animated-title > div {
  overflow: hidden;
  width: 100%;
}


.home-hero .animated-title > div.text-top div {
  animation: showTopText 2s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  bottom: 0;
  transform: translate(0, 100%);
}

.home-hero .animated-title > div.text-bottom div {
  animation: showBottomText 1s;
  animation-delay: 4s;
  animation-fill-mode: forwards;
  top: 0;
  transform: translate(0, -200%);
}


/* IMAGE SECTION */
.hero-right svg {
  background:url('/public/images/dj.jpg');
  background-size:cover;
  max-width: 45vmin;
  min-width: 40vmin;
  width: auto;
  height: auto;
  display: block;
  box-shadow: 0px 0px 30px 5px rgba(0,0,0,.3);
  transform: rotate(2deg); 
  border: 10px solid #fff;
}

.hero-right text{
  font-size:10px;
  transition:font-size .5s ease-out;
  font-weight:900;
  font-family:arial;
}

.hero-right svg:hover text{
  transition:font-size .5s ease-in;
  font-size: 300px;
}


.laptop, .teamwork, .web-designer {
	overflow: hidden;
	transform: translatey(0px);
	animation: float 7s ease-in-out infinite;
  background: transparent;
}
  
.teamwork {
  animation: float 3s ease-in-out infinite;
}

.web-designer {
  animation: float 5s ease-in-out infinite;
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}


@media only screen and (max-width: 768px) {
  .hero-right {
    display: none;
  }
  .hero-images img {
    width: 70px;
  }

}