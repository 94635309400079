.project-page i {
  font-style: normal;
  margin: 1rem 0px;
}

.project-page i::before {
  margin-right: 5px;
}

.project-page .design-link:hover {
  color: #0bb073;
}